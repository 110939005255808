import './App.css';
import React, { useState } from 'react';
import IconDiv1 from './div1.svg';
import IconDiv2 from './div2.svg';
import IconDiv3 from './div3.svg';
import IconDiv4 from './div4.svg';
import IconDiv5 from './div5.svg';
import gratitud from './Gratitud.svg';
import estrellaLogo from './LogoEstrella.svg';


// Array con los íconos
const icons = [IconDiv1, IconDiv2, IconDiv3, IconDiv4, IconDiv5];

function App() {
  const [inputValue, setInputValue] = useState('');
  const [clickedDiv, setClickedDiv] = useState('');
  const [viewMessage, setViewMessage] = useState(false);
  const [viewMessageError, setViewMessageError] = useState(false);

  const handleDivClick = (divId) => {
    setClickedDiv(divId);

    if (inputValue.length > 0) {
      setViewMessage(true)
      
    } else {
      setViewMessageError(true)
    }


  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <>
    <div>
      {/* Imagen en la esquina superior izquierda */}
      {viewMessage && (<div className="top-left-logo">
          <img src={gratitud} alt="Logo Gratitud" />
      </div>)}

        {/* Imagen en la esquina superior derecha */}
        <div className="top-right-logo">
          <img src={estrellaLogo} alt="Logo Estrella" />
        </div>
    </div>
    <div className="App">
  
  <header className="App-header m-5">
    {!viewMessage && (
      <form>
        <div>
          <img src={gratitud} className="App-logo" alt="logo" />
        </div>
        <div className="space-y-12">
          <p className={`text-lg text-center text-[#40E0D0] textfont`}>
            Hemos preparado un mensaje para ti...
          </p>
          <div className="relative z-0 w-full mb-5">
            <input
              type="text"
              name="name"
              placeholder=" "
              required
              value={inputValue}
              onChange={handleInputChange}
              className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 border-gray-200 textfont textWhite"
            />
            <label
              htmlFor="name"
              className="absolute duration-300 top-3 -z-1 origin-0 text-[#40E0D0] textfont text-center"
            >
              ¿Cómo te dicen tus amigos?
            </label>
            {viewMessageError && (
              <span className="text-sm text-red-600 textfont" id="error">
                Nombre requerido
              </span>
            )}
          </div>
        </div>
        <p className={`text-lg text-left text-[#40E0D0] textfont mt-5`}>
          Selecciona tu color favorito.
        </p>
        <div className="grid grid-cols-5 gap-4 mt-5">
          {['div1', 'div2', 'div3', 'div4', 'div5'].map((divId, index) => (
            <div
              key={divId}
              onClick={() => handleDivClick(divId)}
              className={`cursor-pointer p-4 flex items-center justify-center hover:bg-gray-800 ${
                clickedDiv === divId ? 'bg-gray-600' : ''
              }`}
            >
              <img src={icons[index]} alt={`Icono ${index + 1}`} className="h-8 w-8" />
            </div>
          ))}
        </div>
      </form>
    )}
    { viewMessage && (
      <div className='text-container'>
          <div className='text-justify al'>
            <p className={`font-quinsi textWhite text-center ${clickedDiv} text-7xl`}>
            {inputValue}
          </p>
          <p className='lexend-text text-center'>
            Esto es lo que sabemos, cada miembro de Educación Estrella® ha influido e influye directamente en quienes somos y hasta donde hemos llegado hoy. Cada estudiante y los suyos, cada Universidad, cada filántropo e inversionista, y por supuesto nuestro equipo interno, contribuye a la composición general de nuestra gran familia.
            <br></br>
            <br></br>
            El Día de Acción de Gracias nos permite destacar y celebrar la importancia de cada uno de Uds., resaltar sus virtudes y atributos únicos que garantizan colectivamente la buena semilla que sembramos y la cosecha que recibimos y que, especialmente, reciben y continuarán recibiendo miles de jóvenes en toda Latinoamérica.
            <br></br>
            <br></br>   
            Son cinco años ya, en los cuales la cultura, valores y experiencias únicas de cada miembro; se integran de lleno en la identidad de Estrella, un primer quinquenio donde las fortalezas que cada uno aporta, intensifica el brillo del propósito común que nos convoca.
            <br></br>
            <br></br>
            Tienen de nuestra parte, gratitud infinita y el compromiso intacto de continuar construyendo un camino que honre todo el potencial de nuestra sinergia y la providencia que nos permite impactar y bendecir a tantos.
            <br></br>
            <br></br>
            <p className={`font-quinsi textWhite text-center ${clickedDiv} text-7xl`}>
              ¡Feliz Día!
            </p>
          </p>
          </div>

      </div>
      )}
  </header>
    </div>
    </>

  );
}

export default App;
